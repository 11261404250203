import React from "react";
import { Row, Col, Icon, Button, Badge } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const MobileBottomNavBar = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const iconStyle = { fontSize: "20px" };

  if (user.userData && user.userData.isAuth) {
    return (
      <div className="mobile-nav-bar">
        <Row type="flex" justify="space-around" align="middle">
          <Col span={4}>
            <Link to="/notifications" className="mobile-nav-bar-link">
              <div className="bottom-navbar-icon-container">
                <Badge
                  count={user.userData && user.userData.notifications.length}
                  overflowCount={99}
                >
                  <Icon type="bell" style={iconStyle} />
                </Badge>
                <span className="bottom-navbar-text">{t("navbar.notfi")}</span>
              </div>
            </Link>
          </Col>
          <Col span={4}>
            <Link to="/deliveryProfile" className="mobile-nav-bar-link">
              <div className="bottom-navbar-icon-container">
                <Badge
                  count={
                    user.userData &&
                    user.userData.deliveryOrders.filter(
                      (delivery) => delivery.acceptDelivery
                    ).length
                  }
                  overflowCount={99}
                >
                  <Icon type="car" style={iconStyle} />
                </Badge>
                <span className="bottom-navbar-text">
                  {t("mobileBottomNavbar.delOrders")}
                </span>
              </div>
            </Link>
          </Col>
          <Col span={4}>
            <Link to="product/upload">
              <div className="bottom-navbar-icon-container">
                <Button type="primary" icon="upload" style={iconStyle} />
              </div>
            </Link>
          </Col>
          <Col span={4}>
            <Link to="/orders" className="mobile-nav-bar-link">
              <div className="bottom-navbar-icon-container">
                <Badge
                  count={user.userData && user.userData.orders.length}
                  overflowCount={99}
                >
                  <Icon type="shop" style={iconStyle} />
                </Badge>
                <span className="bottom-navbar-text">{t("navbar.order")}</span>
              </div>
            </Link>
          </Col>
          <Col span={4}>
            <Link to="/profile" className="mobile-nav-bar-link">
              <div className="bottom-navbar-icon-container">
                <Icon type="user" style={iconStyle} />
                <span className="bottom-navbar-text">
                  {t("navbar.mProfile")}
                </span>
              </div>
            </Link>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="mobile-nav-bar">
        <Button.Group size="large">
          <Link to="/register">
            <Button type="primary">{t("navbar.signup")}</Button>
          </Link>
          <Link to="/login">
            <Button>{t("navbar.singin")}</Button>
          </Link>
        </Button.Group>
      </div>
    );
  }
};

export default MobileBottomNavBar;
